// Morphing Device by Edmundo Santos

$bg-color: #202126;
$outline: rgba(255,255,255,.1);

.device-container {
  min-width: 12rem;
  min-height: 12rem;
  width: 24rem;
  height: 24rem;
  transform: rotateY(-35deg);
  padding-top: 4rem;
}

.ir {
  font: 0/0 a;
  text-shadow: none;
  color: transparent;
}

.device {
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-top: 70%;
  margin: 0 auto;
  border: 2px solid #ebcb63;
  border-radius: 6px;
  background: #13131A;
  box-shadow: 10px 10px 0 -2px $bg-color, 10px 10px 0 rgba(255,255,255,.1);
  backface-visibility: hidden;
  animation: morphDevice 10s infinite cubic-bezier(1,.015,.295,1.225) forwards;

  figcaption {
    position: absolute;
    top: 12px;
    left: 12px;
    width: 10px;
    height: 10px;
    background: rgba(255,255,255,.1);
    box-shadow: 15px 0 0 rgba(255,255,255,.1), 30px 0 0 rgba(255,255,255,.1);
    border-radius: 50%;
    animation: morphButtons 10s infinite cubic-bezier(1,.015,.295,1.225) forwards;
    backface-visibility: hidden;
  }
}

@keyframes morphDevice {
  0%, 15% {
    width: 100%;
    padding-top: 70%;
    margin-top: 0;
    border-color: #ebcb63;
    box-shadow: 10px 10px 0 -2px $bg-color, 10px 10px 0 $outline;
    transform: rotate(0);
  }
  25%, 40% {
    width: 70%;
    padding-top: 55%;
    margin-top: 7.5%;
    border-color: #4b8ac4;
    box-shadow: 9px -9px 0 -2px $bg-color, 9px -9px 0 $outline;
    transform: rotate(90deg);
  }
  50%, 65% {
    width: 28%;
    padding-top: 42%;
    margin-top: 15%;
    border-color: #ece9f2;
    box-shadow: -8px -8px 0 -2px $bg-color, -8px -8px 0 $outline;
    transform: rotate(180deg);
  }
  75%, 90% {
    width: 55%;
    padding-top: 70%;
    margin-top: 0;
    border-color: #57c39f;
    box-shadow: -9px 9px 0 -2px $bg-color, -9px 9px 0 $outline;
    transform: rotate(270deg);
  }
  100% {
    width: 100%;
    padding-top: 70%;
    margin-top: 0;
    border-color: #ebcb63;
    box-shadow: 10px 10px 0 -2px $bg-color, 10px 10px 0 $outline;
    transform: rotate(360deg);
  }
}

@keyframes morphButtons {
  0%, 15%, 100% {
    top: 12px;
    left: 12px;
    margin: 0;
    background: #ebcb63;
    box-shadow: 15px 0 0 rgba(255,255,255,.1), 30px 0 0 rgba(255,255,255,.1);
    transform: scale(1);
  }
  25%, 40% {
    top: 50%;
    left: 100%;
    margin: -5px 0 0 -20px;
    background: #4b8ac4;
    box-shadow: 0 0 0 350px rgba(255,255,255,0.05), 0 0 0 100px transparent;
    transform: scale(.9);
  }
  50%, 65% {
    top: 10px;
    left: 50%;
    margin: 0 0 0 -5px;
    background: #ece9f2;
    box-shadow: 0 -30px 0 rgba(255,255,255,.05), 0 -30px 0 rgba(255,255,255,.05);
    transform: scale(.8);
  }
  75%, 90% {
    top: 100%;
    left: 50%;
    margin: -20px 0 0 -5px;
    background: #57c39f;
    box-shadow: 0 0 0 350px rgba(255,255,255,0.05), 0 0 0 100px transparent;
    transform: scale(.9);
  }
}

@media (min-width: 1610px) {
  .device-container {
    min-width: 16rem;
    min-height: 16rem;
    width: 32rem;
    height: 32rem;
    padding-top: 4rem;
  }
}