@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Bebas Neue';
  src: url("/assets/clapps/fonts/BebasNeue Regular.ttf") format("truetype");
}

@font-face {
  font-family: 'Bebas Neue Bold';
  src: url("/assets/clapps/fonts/BebasNeue Bold.ttf") format("truetype");
}

@font-face {
  font-family: 'Open Sans Condensed Regular';
  src: url("/assets/clapps/fonts/OpenSans_Condensed-Regular.ttf") format("truetype");
}

/*@layer components {*/
/*  .row-center {*/
/*    @apply flex flex-row items-center justify-center;*/
/*  }*/
/*  .col-center {*/
/*    @apply flex flex-col items-center justify-center;*/
/*  }*/
/*  .col-h-screen {*/
/*    @apply w-full h-screen flex flex-col;*/
/*  }*/
/*  .activeProf {*/
/*    @apply border-b-4 border-t-0 border-r-0 border-l-0 border-solid border-green6;*/
/*  }*/
/*}*/

@layer base {
  /*body {*/
  /*  @apply bg-black-1*/
  /*}*/
}

:root {
  --font-mono: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'BebasNeue Regular', 'Open Sans Condensed Regular', Helvetica, ui-monospace, Menlo, Monaco, 'Cascadia Mono', 'Segoe UI Mono',
    'Roboto Mono', 'Oxygen Mono', 'Ubuntu Monospace', 'Source Code Pro',
    'Fira Mono', 'Droid Sans Mono', 'Courier New', monospace;
}

* {
  -webkit-tap-highlight-color: transparent;
}

html {
  /*scroll-behavior: smooth!important;*/
}

h1{
  margin-block-start: 0;
  margin-block-end: 0;
  margin-inline-start: 0;
  margin-inline-end: 0;
}

h2{
  margin-block-start: 0;
  margin-block-end: 0;
  margin-inline-start: 0;
  margin-inline-end: 0;
}

h3{
  margin-block-start: 0;
  margin-block-end: 0;
  margin-inline-start: 0;
  margin-inline-end: 0;
}

h4{
  margin-block-start: 0;
  margin-block-end: 0;
  margin-inline-start: 0;
  margin-inline-end: 0;
}

p{
  margin-block-start: 0;
  margin-block-end: 0;
  margin-inline-start: 0;
  margin-inline-end: 0;
}

a {
  text-decoration: none;
}

body {
  margin: 0;
  line-height: 1;
  overflow-y:scroll;
  /*background-color: #13131A;*/
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Helvetica, serif;
}

.MuiDrawer-paper {
  background: transparent;
}

.MuiAutocomplete-popper {
  padding-top: 0.75rem;
}

.MuiAutocomplete-popper > div{
  border-radius: 14px;
  box-shadow: 0 5px 5px -3px rgba(0,0,0,0.2), 0 8px 10px 1px rgba(0,0,0,0.14), 0 3px 14px 2px rgba(0,0,0,0.12);
}

/*.image-gallery-image{*/
/*  max-height: 80vh;*/
/*}*/

ymaps {
  border-radius: 0;
}

.nowrap {
  white-space: nowrap;
}

.perspective{
  perspective: 1000px;
}

.cool-btn-left i,
.cool-btn-right i{
  position: absolute;
  display: block;
  inset: -2px;
}

.cool-btn-left i::before {
  content: '';
  position: absolute;
  top:0;
  left: 80%;
  width: 10px;
  height: 0;
  background: #E7E7E7;
  transition-duration: 0.75s;
}

.cool-btn-left i::after {
  content: '';
  position: absolute;
  bottom:0;
  left: 20%;
  width: 10px;
  height: 0;
  background: #E7E7E7;
  transition-duration: 0.75s;
}

.cool-btn-right i::before {
  content: '';
  position: absolute;
  top:0;
  left: 20%;
  width: 10px;
  height: 0;
  background: #E7E7E7;
  transition-duration: 0.75s;
}

.cool-btn-right i::after {
  content: '';
  position: absolute;
  bottom:0;
  left: 80%;
  width: 10px;
  height: 0;
  background: #E7E7E7;
  transition-duration: 0.75s;
}

.cool-btn-left:hover i::after,
.cool-btn-left:hover i::before,
.cool-btn-right:hover i::after,
.cool-btn-right:hover i::before {
  width: 100%;
  left: 0;
  background: #ff4833;
  height: 2px;
}

.anim-up-50 {
  animation: 0.5s anim-goUp ease-out;
}
.anim-up-75 {
  animation: 0.75s anim-goUp ease-out;
}
.anim-up-100 {
  animation: 1s anim-goUp ease-out;
}
.anim-up-125 {
  animation: 1.25s anim-goUp ease-out;
}
@keyframes anim-goUp {
  0% {
    opacity: 0;
    transform: translateY(40%);
  }
  100% {
    opacity: 1;
    transform: translateY(0%);
  }
}

.cool-btn-hover .cool-hover {
  height: 0;
  opacity: 0;
  font-size: 0;
  transition-duration: 0.5s;
}

.cool-btn-hover:hover .cool-hover {
  height: 4rem;
  opacity: 1;
  font-size: 1.25rem;
}

.svg-green-hover:hover {
  /*brightness(0) saturate(100%)*/
  filter: brightness(0) saturate(100%) invert(59%) sepia(52%) saturate(2677%) hue-rotate(79deg) brightness(95%) contrast(102%);
}

.filter-svg-white {
  filter: brightness(0) saturate(100%) invert(99%) sepia(2%) saturate(673%) hue-rotate(342deg) brightness(114%) contrast(100%);
}

.filter-svg-green {
  filter: brightness(0) saturate(100%) invert(71%) sepia(50%) saturate(541%) hue-rotate(69deg) brightness(96%) contrast(90%);
}

.filter-svg-purple {
  filter: brightness(0) saturate(100%) invert(66%) sepia(38%) saturate(713%) hue-rotate(198deg) brightness(92%) contrast(84%);
}

.filter-svg-yellow {
  filter: brightness(0) saturate(100%) invert(93%) sepia(100%) saturate(4638%) hue-rotate(323deg) brightness(100%) contrast(95%);
}

.button-green:hover {
  background-color: #5BDB5E;
  border-color: #5BDB5E;
}

.button-purple:hover {
  background-color: #919AD9;
  border-color: #919AD9;
}

.button-yellow:hover {
  background-color: #F9CD3E;
  border-color: #F9CD3E;
}

.drop-out-menu {
  color: black;
}

.drop-out-menu-title {
  cursor: pointer;
}

.drop-out-menu-title:hover {
  color: rgb(12, 193, 16);
}

.drop-out-menu-ul {
  position: absolute;
  filter: drop-shadow(1px 2px 4px rgb(227 227 227));
  width: 14rem;
}

.drop-out-menu-ul-2 {
  position: absolute;
  filter: drop-shadow(1px 2px 4px rgb(227 227 227));
}

.drop-out-menu ul {
  display: flex;
  flex-direction: column;
  background: white;
  gap: 10px;
}

.drop-out-menu li {
  display: flex;
  flex-direction: row;
  cursor: pointer;
  color: black;
}

.drop-out-menu ul,
.drop-out-menu li {
  margin: 0;
  padding: 10px;
  list-style: none;
}

.drop-out-menu li:hover {
  color: rgb(12, 193, 16);
}

@media (prefers-color-scheme: dark) {
  html {
    color-scheme: dark;
  }
}



@media (min-width: 1024px) {
  /*body {*/
  /*  overflow:hidden;*/
  /*}*/

  ymaps {
    border-radius: 1.5rem;
  }
}

@media (min-width: 1610px) {
  .cool-btn-hover:hover .cool-hover {
    height: 5.5rem;
    opacity: 1;
    font-size: 1.5rem;
  }
}